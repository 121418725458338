import React from 'react'
import { KharchaCard, KharchaHomeContainer, KharchaLink, KharchaLogo, KharchaMainHeading, KharchaText } from './KharchaHome.elements'

import WallpapersProImage from '../../assets/images/kharcha_icon.png';
import { Link } from 'react-router-dom';

function KharchaHome() {
  return (
    <KharchaHomeContainer>
      <KharchaCard>
        <KharchaLogo src={WallpapersProImage}></KharchaLogo>
        <KharchaMainHeading>Kharcha</KharchaMainHeading>
        <KharchaText>
          One App for all your Kharcha (expense) tracking needs! Kharcha.
        </KharchaText>
        <KharchaLink to={'/kharcha/privacy'}> Privacy Policy </KharchaLink>
      </KharchaCard>
    </KharchaHomeContainer>
  )
}

export default KharchaHome