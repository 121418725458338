import styled from "styled-components";


export const SocialShareWrapper = styled.div`
    width: 100%;
`;

export const SocialShareHeading = styled.h4`
    font-family: var(--blog-heading-font);
    font-size: 1.2rem;
    width: 100%;
    border-bottom: 1px solid #161c2722;
    padding-bottom: 0.4rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
`;

export const SocialShareItems = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
`;

export const SocialLinkItem = styled.div`
    margin-right: 1rem;
    margin-top: 0.3rem;
`;