import axios from "axios";
import { useState, useEffect } from "react";


const useFetchPostBySlug = (slug) => {
    const abortController = new AbortController()

    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        axios
            .get(
                "https://prabhatsdp.com/wp-json/wp/v2/posts",
                {
                    params: { slug: slug, _embed:"wp:term" },
                    signal: abortController.signal
                }
            )
            .then((response) => {
                console.log("RESPONSE==> ", response)
                const list = response.data
                console.log("List of posts==> ", list)
                if (list.length != 0) {
                    var post = list[0]
                    const data = {}
                    data.post = post
                    const meta = parseMeta(post)
                    data.meta = meta
                    setData(data)
                    setIsPending(false)
                    setError(null)
                } else {
                    setData(null)
                    setIsPending(false)
                    setError("No post found")
                }
            })
            .catch((err) => {
                console.log("ERROR==> ", err)
                if (err.name === 'AbortError') {
                    console.log("fetch Aborted")
                } else {
                    setIsPending(false)
                    setError(err.message)
                }
            })
        // abort the fetch
        return () => abortController.abort();
    }, [slug])

    return { data, isPending, error };

}

const parseMeta = (post)  => {
    const seo = {}
    const metaJson = post.yoast_head_json
    if(metaJson) {
        seo.title = metaJson.title
        seo.desc = metaJson.og_description
        seo.slug = post.slug
        if(metaJson.robots) {
            const robots = []
            Object.keys(metaJson.robots).forEach((key) => {
                robots.push(metaJson.robots[key])
            })
            seo.robots = robots
        }
        seo.publishedTime = metaJson.article_published_time
        seo.modifiedTime = metaJson.article_modified_time
        if(metaJson.og_image && metaJson.og_image[0]) {
            const image = metaJson.og_image[0]
            seo.image = image.url
            seo.imageWidth = image.width
            seo.imageHeight = image.height
            seo.imageType = image.type
        }
        seo.twitterCard = metaJson.twitter_card
        seo.twitterCreator = metaJson.twitter_creator
        if(metaJson.twitter_misc) {
            const keyValues = []
            Object.keys(metaJson.twitter_misc).forEach((key) => {
                keyValues.push({label: key, data: metaJson.twitter_misc[key]})
            })
            seo.twitterKeyValues = keyValues
        }
    }
    return seo;
}

export default useFetchPostBySlug