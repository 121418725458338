import React from 'react'
import ProfileImageUrl from '../../../../assets/images/prabhat-pandey-image.webp'
import format from 'date-fns/format'
import { Link } from 'react-router-dom'
import {
    AuthorImage,
    AuthorName,
    AuthorSection,
    AuthorColumn,
    ArticleDetails,
    AuthorSocial,
    AuthorNameContainer,
    ArticleDetailsItem,
    ArticleDetailSeparator,
    AuthorSocialLink,
    Facebook,
    Twitter,
    LinkedIn,
    GitHub
} from './Author.elements'
import { FacebookIcon, IconLink } from '../../../../components/SocialBar/SocialBar.elements'
import ReadingTime from './ReadingTime'

const Author = ({ time, content }) => {
    return (
        <>
            <AuthorSection>
                <AuthorImage src={ProfileImageUrl} />
                <AuthorColumn>
                    <AuthorNameContainer>
                        <AuthorName>Prabhat Pandey</AuthorName>
                    </AuthorNameContainer>
                    <ArticleDetailsItem>
                        @prabhatsdp
                    </ArticleDetailsItem>
                </AuthorColumn>
                <ArticleDetails>
                    <ArticleDetailsItem>
                        {format(new Date(time), "dd MMM, yyyy")}
                    </ArticleDetailsItem>
                    <ArticleDetailSeparator />
                    <ReadingTime content={content}></ReadingTime>
                </ArticleDetails>
                {/* <AuthorSocial>
                    <AuthorSocialLink><Facebook isDarkMode={false} className={`icon social-icon`} /></AuthorSocialLink>
                    <AuthorSocialLink><Twitter isDarkMode={false} className={`icon social-icon`} /></AuthorSocialLink>
                    <AuthorSocialLink><LinkedIn isDarkMode={false} className={`icon social-icon`} /></AuthorSocialLink>
                    <AuthorSocialLink><GitHub isDarkMode={false} className={`icon social-icon`} /></AuthorSocialLink>
                </AuthorSocial> */}
            </AuthorSection>
        </>
    )
}

export default Author