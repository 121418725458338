
import React from 'react';

import './assets/styles/App.css';
import './assets/styles/animate.min.css';
import { 
  createBrowserRouter,
  RouterProvider,
  Route
 } from 'react-router-dom';

import Home from './screens/home/Home';
import KharchaPolicy from './screens/privacy/KharchaPolicy';
import SitePolicy from './screens/privacy/SitePolicy';
import KharchaHome from './screens/kharcha-home/KharchaHome';
import { BlogScreen } from "./screens/blog"
import { BlogHomeScreen } from './screens/blog/pages/homepage';
import BlogPost from './screens/blog/pages/postpage/BlogPost';
import NotFound from './screens/error/NotFound';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
    errorElement: <NotFound/>
  },
  {
    path:"/blog",
    element: <BlogScreen/>,
    children: [
      {
        path:"",
        element: <BlogHomeScreen/>,
      }, {
        path: ":slug",
        element: <BlogPost />
      }
    ]
  },
  {
    path:"/kharcha",
    element: <KharchaHome />
  },
  {
    path:"/kharcha/privacy",
    element: <KharchaPolicy />
  },
  {
    path:"/privacy",
    element: <SitePolicy />
  }
])

const App = () => {
  return (
    <RouterProvider router={router}/>
  )
}

export default App


// function App() {

//   return (
//     <Router>
//       <Switch>
//         <Route exact path={"/blog"}>
//           <BlogScreen/>
//         </Route>
//         <Route exact path={'/kharcha/privacy'}>
//           <KharchaPolicy />
//         </Route>
//         <Route exact path={'/kharcha'}>
//           <KharchaHome/>
//         </Route>
//         <Route exact path="/">
//           <Home />
//         </Route>
//       </Switch>
//     </Router>

//   );
// }

// export default App;
