import React from 'react';
import SectionHeading from '../../components/SectionHeading/SectionHeading';
import WorkItem from '../../components/WorkItem/WorkItem';
import { portfolioData } from '../../data/app_data';
import {
    PortfolioContainer,
    WorkItemsContainer,
    WorkItemWrap
} from './PortfolioSection.elements';

const PortfolioSection = ({ isDarkMode }) => {
    return (
        <>
            <PortfolioContainer
                className="portfolio-container"
                id="works-section"
                name="work-section"
            >
                <SectionHeading text={portfolioData.title} isDarkMode={isDarkMode} />
                <WorkItemsContainer className="work-items">
                    {portfolioData.works.map((work) => (
                        <WorkItemWrap key={work.title}>
                            <WorkItem
                                imageUrl={work.imageUrl}
                                isDarkMode={isDarkMode}
                                title={work.title}
                                techs={work.tech}
                                githubLink={work.githubLink}
                                otherLink={work.siteLink}
                                imageAltText={work.imageAltText}
                                className="item"
                            />
                        </WorkItemWrap>
                    ))}
                </WorkItemsContainer>
            </PortfolioContainer>
        </>
    );
};

export default PortfolioSection;
