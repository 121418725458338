import styled from 'styled-components';
// import ProfilePhoto from '../../assets/images/profile_photo.webp'


export const MainPhoto = styled.img`
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    position: absolute;
    z-index: 3;
    border-radius: 10%;
`;

export const PhotoOverlay = styled.div`
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    position: absolute;
    z-index: 3;
    border-radius: 10%;
    background-color: ${props => props.isDarkMode ? "var(--image-overlay-color)" : "transparent"};
    transition: background-color 0.5s ease-in-out, opacity 0.5s ease-in-out;
`;

export const DashedBg = styled.img`
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    position: absolute;
    top: 32px;
    left: 32px;
    transition: all 0.5s ease-in-out;
    z-index: 2;
`;

export const PhotoContainer = styled.div`
    width: ${props => props.portfolio ? "85%" : "100%"};
    aspect-ratio: 1;
    position: relative;

    &:hover {
        ${DashedBg} {
            top: 24px;
            left: 24px;
        }
        ${PhotoOverlay} {
            opacity: 0;
        }
    }
`;