import styled from 'styled-components';


export const BtnText = styled.span`
    z-index: 3;
    transition: all 0.5s ease-in-out;
    color: ${props => props.isDarkMode ? "var(--primary-color)" : "var(--day-text-color)"};
    margin: 16px 32px;
`;
export const BtnBase = styled.span`
    position: absolute;
    left: 0;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    transition: all 0.5s ease-in-out;
    border: 2px solid var(--primary-color);
    background-color: ${props => props.isDarkMode ? "transparent" : "var(--primary-color)"};
`;

export const BtnHoverBg = styled.span`
    position: absolute;
    left: 0;
    width: 0%;
    border-radius: 8px;
    background-color: ${props => props.isDarkMode ? "var(--primary-color)" : "var(--primary-color-light)"};
    height: 100%;
    transition: all 0.5s ease-in-out;
`;

export const BtnLink = styled.a`
    display: block;
    height: fit-content;
    border-radius: 8px;
    width: fit-content;
    margin-top: 42px;
    font-weight: 500;
    font-size: 18px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;

    &:hover{
        ${BtnHoverBg} {
            width: 100%;
        }

        ${BtnText} {
            color: var(--night-surface-color);
        }
        ${BtnBase} {
            /* border: 2px solid ${props => props.isDarkMode ? "var(--primary-color)" : "var(--primary-color-dark)"}; */
        }
    }
`;
