import styled from 'styled-components';
import {IoMoon, IoSunny} from 'react-icons/io5'
import { Link } from 'react-router-dom';

export const BtnBackground = styled(Link)`
    height: 24px;
    width: 48px;
    background-color: var(--secondary-color);
    border-radius: 16px;
    position: relative;

`;

export const BtnSlider = styled.div`
    width: 18px;
    height: 18px;
    border-radius: 12px;
    background-color: var(--primary-color);
    position: absolute;
    left: ${props => props.isLeft ? "4px" : "26px"};
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    top: 3px;
    transition: all 0.5s ease-in-out;
    /* visibility: hidden; */
    z-index: 999;
`;

export const SunIcon = styled(IoSunny)`
    width: 18px;
    height: 18px;
    position: absolute;
    color: var(--sun-yellow-color);
    left: 4px;
    top: 3px;
`;

export const MoonIcon = styled(IoMoon)`
    width: 18px;
    height: 18px;
    position: absolute;
    color: white;
    right: 4px;
    top: 3px;
`;