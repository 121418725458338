import styled from 'styled-components';
import { Section } from '../../App.elements';
import { device } from '../../configs/breakpoints';

export const AboutContainer = styled(Section)`


`;

export const AboutDetails = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 48px;

    @media ${device.laptopS} {
        flex-direction: row;
    }
`;

export const AboutTextContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media ${device.laptopS} {
        flex: 3;
        margin-right: 32px;
    }
`;

export const AboutImageContainer = styled.div`
    width: 80%;
    margin-top: 48px;
    align-self: center;
    @media ${device.laptopS} {
        flex: 2;
        align-self: flex-start;
        margin-top: 16px;
    }
`;

export const AboutText = styled.p`
    margin-top: 16px;
    font-size: 16px;
`;

export const SkillsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 16px;
`;

export const SkillsColumn = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`;