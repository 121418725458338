import React, { useEffect, Component } from 'react'
import Prism from 'prismjs'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-kotlin'
import 'prismjs/components/prism-java'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-cshtml'
import 'prismjs/components/prism-xml-doc'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-dart'
import '../../../../assets/styles/prism-coldark-dark.css'
import { BlogContent, BlogContentArticle, BlogContentSidebar, BlogHeading, SidebarCard } from './BlogPost.elements'
import Author from '../../components/author/Author'
import RelatedPosts from '../../components/related/RelatedPosts'
import SocialShare from '../../components/socialshare/SocialShare'


const BlogSection = ({ post }) => {

    useEffect(() => {
        console.log("Highlighting with prism")
        Prism.highlightAll()
    })

    return (
        <>
            <BlogContentArticle className='blog-article blog-content'>
                <Author time={post.modified_gmt} content={post.content.rendered} />
                <BlogHeading>{post.title.rendered}</BlogHeading>
                <BlogContent dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
            </BlogContentArticle>
            <BlogContentSidebar>
                <SidebarCard>
                    <SocialShare>
                        
                    </SocialShare>
                    <RelatedPosts categoryIds={post.categories.toString()} postId={post.id}/>
                </SidebarCard>
            </BlogContentSidebar>
        </>
    )
}

export default BlogSection

