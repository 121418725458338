import React from 'react'
import Lottie from 'lottie-react'
import animationData from '../../assets/anim/loading.json'
import { LoadingContainer } from './Loading.elements'


const Loading = () => {

  return (
    <>
      <LoadingContainer className='lottie-anim'><Lottie animationData={animationData} loop={true} /></LoadingContainer>
    </>
  )
}

export default Loading