import React from 'react';
import { AnimateEnter } from '../../App.elements';
import Skill from '../Skill/Skill';

import {
    ItemContainer,
    WorkCard,
    WorkImage,
    WorkTitle,
    TechUsedContainer,
    Icons,
    OtherSiteIcon,
    GithubIcon,
    ProjectLink
} from './WorkItem.elements';

const WorkItem = ({
    imageUrl,
    imageAltText,
    isDarkMode,
    title,
    techs,
    githubLink,
    otherLink
}) => {
    return (
        <>
            <AnimateEnter>
                <ItemContainer className="item-container">
                    <WorkImage
                        imageUrl={imageUrl}
                        showOverlay={true}
                        isDarkMode={isDarkMode}
                        photoWidth="90%"
                        portfolio
                        altText={imageAltText} />
                    <WorkCard isDarkMode={isDarkMode}>
                        <WorkTitle
                            className="work-title"
                            isDarkMode={isDarkMode}>
                            {title}
                        </WorkTitle>

                        <TechUsedContainer className="tech-used">
                            {techs.map((tech) => (
                                <Skill skillTitle={tech} portfolio key={tech} />
                            ))}
                        </TechUsedContainer>
                        <Icons className="icons">
                            {githubLink != null ? (
                                <ProjectLink href={githubLink} target="_blank">
                                    <GithubIcon isDarkMode={isDarkMode} />
                                </ProjectLink>
                            ) : null}
                            {otherLink != null ? (
                                <ProjectLink href={otherLink} target="_blank">
                                    <OtherSiteIcon isDarkMode={isDarkMode} />
                                </ProjectLink>
                            ) : null}
                        </Icons>
                    </WorkCard>
                </ItemContainer>
            </AnimateEnter>
        </>
    );
};

export default WorkItem;
