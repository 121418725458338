import styled from "styled-components";
import { Link } from "react-router-dom";

export const RelatedWrapper = styled.div`
    width: 100%;
`;

export const RelatedHeading = styled.h4`
    font-family: var(--blog-heading-font);
    font-size: 1.2rem;
    width: 100%;
    border-bottom: 1px solid #161c2722;
    padding-bottom: 0.4rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
`;

export const RelatedItemWrapper = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    cursor: pointer;
    color: var(--day-text-color-blog);
`;

export const RelatedItemImage = styled.img`
    width: 4rem;
    height: 2.5rem;
    margin-right: 0.5rem;
    border-radius: 0.5rem;
    object-fit: cover;
`;

export const RelatedItemDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const RelatedItemHeading = styled.h5`
    font-family: var(--blog-heading-font);
    font-size: 0.9rem;
    font-weight: 600;
`;

export const RelatedItemAuthor = styled.p`
    font-family: var(--blog-heading-font);
    font-size: 0.6rem;
    opacity: 0.7;
`;
