import React from 'react'
import { Helmet } from 'react-helmet'
import PersonalImage from '../../assets/images/play_store_banner_1080.webp'

const BlogPostMeta = ({ metaDetails }) => {
    return (
        <>
            <Helmet>
                <title>{metaDetails.title}</title>
                <meta name="robots" content={metaDetails.robots.join(", ")} />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={metaDetails.title} />
                <meta property="og:description" content={metaDetails.desc} />
                <meta property="description" content={metaDetails.desc} />
                <meta property="og:url" content={"https://prabhatpandey.dev/blog/" + metaDetails.slug} />
                <meta property="og:site_name" content="Prabhat Pandey" />
                <meta property="article:author" content="https://facebook.com/prabhatsdp" />
                <meta property="article:published_time" content={metaDetails.publishedTime} />
                <meta property="article:modified_time" content={metaDetails.modifiedTime} />
                <meta property="og:image" content={metaDetails.image ? metaDetails.image : PersonalImage} />
                {metaDetails.imageWidth && <meta property="og:image:width" content={metaDetails.imageWidth} />}
                {metaDetails.imageHeight && <meta property="og:image:height" content={metaDetails.imageHeight} />}
                {metaDetails.imageType && <meta property="og:image:type" content={metaDetails.imageType} />}
                <meta name="author" content="Prabhat Pandey" />
                <meta name="twitter:card" content={metaDetails.twitterCard} />
                <meta name="twitter:creator" content={metaDetails.twitterCreator} />

                {
                    metaDetails.twitterKeyValues.map((item, index) => {
                        return (
                            <meta name={"twitter:label" + (index + 1)} content={item.label} />
                        )
                    })
                }
                {
                    metaDetails.twitterKeyValues.map((item, index) => {
                        return (
                            <meta name={"twitter:data" + (index + 1)} content={item.data} />
                        )
                    })
                }
            </Helmet>
        </>
    )
}

export default BlogPostMeta