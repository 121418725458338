import styled from 'styled-components';

export const HeadingText = styled.h2`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    color: ${props => props.isDarkMode ? "var(--primary-color)" : "var(--day-text-color)"};
    font-family: var(--heading-font);
    font-size: 1.4em;
    transition: all 0.5s ease-in-out;

    &::after {
        background: ${props => props.isDarkMode ? "var(--night-heading-line)" : "var(--day-heading-line)"};
        content: "";
        flex: 1;
        height: 1px;
        transition: all 0.5s ease-in-out;
    }

    &::before {
        background: ${props => props.isDarkMode ? "var(--night-heading-line)" : "var(--day-heading-line)"};
        content: "";
        flex: 1;
        height: 1px;
        transition: all 0.5s ease-in-out;
    }

`;

export const HeadingSpan = styled.span`
    margin: auto 24px;
`;