import styled from 'styled-components';

export const CursorElements = styled.div`
    pointer-events: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    opacity: 1;
    transition: opacity 0.3s ease-in-out, transform 100ms ease-in-out, background 300ms ease-in-out, border 300ms ease-in-out;
    z-index: 9999;
`;

export const DotOutline = styled(CursorElements)`
    width: 40px;
    height: 40px;
    border: 4px solid;
    border-color:  ${props => props.isDarkMode ? "white" : "black"};

    &.hovering {
        border-color: transparent;
        background: ${props => props.isDarkMode ? "#FFFFFF88" : "#00000055"}
    }
`;

export const Dot = styled(CursorElements)`
    width: 10px;
    height: 10px;
    background: ${props => props.isDarkMode ? "white" : "black"};
`;