import styled from "styled-components";
import { FacebookIcon, GithubIcon, IconLink, LinkedInIcon, TwitterIcon } from "../../../../components/SocialBar/SocialBar.elements";

export const AuthorSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
    font-family: var(--blog-heading-font);
`;

export const AuthorImage = styled.img`
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    overflow: hidden;
    margin-right: 1rem;
`;

export const AuthorColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;


export const AuthorNameContainer = styled.div`
    font-weight: 600;
    font-size: 1rem;
`

export const AuthorName = styled.p`
    font-size: 1rem;
`;

export const ArticleDetails = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
    opacity: 0.7;
`;

export const ArticleDetailSeparator = styled.span`
    width: 0.3rem;
    height: 0.3rem;
    margin-left: 0.6rem;
    margin-right: 0.6rem;
    border-radius: 50%;
    background-color: var(--day-text-color-blog);
`;

export const ArticleDetailsItem = styled.p`
    font-size: 0.8rem;
`;

export const AuthorSocial = styled.div`
    flex-grow: 1;
    display: flex;
    margin-left: 1rem;
    flex-direction: row;
    justify-content: flex-end;
`

export const Facebook = styled(FacebookIcon)`
    cursor: pointer;
    width: 1.1rem;
    height: 1.1rem;
    fill: var(--day-text-color-blog);
    stroke: var(--day-text-color-blog);
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
    &:hover {
        fill: var(--primary-color);
        stroke: var(--primary-color);
        opacity: 1;
    }
`;
export const Twitter = styled(TwitterIcon)`
    cursor: pointer;
    width: 1.1rem;
    height: 1.1rem;
    fill: var(--day-text-color-blog);
    stroke: var(--day-text-color-blog);
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
    &:hover {
        fill: var(--primary-color);
        stroke: var(--primary-color);
        opacity: 1;
    }
`;
export const LinkedIn = styled(LinkedInIcon)`
    cursor: pointer;
    width: 1.1rem;
    height: 1.1rem;
    fill: var(--day-text-color-blog);
    stroke: var(--day-text-color-blog);
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
    &:hover {
        fill: var(--primary-color);
        stroke: var(--primary-color);
        opacity: 1;
    }
`;
export const GitHub = styled(GithubIcon)`
    cursor: pointer;
    width: 1.1rem;
    height: 1.1rem;
    fill: var(--day-text-color-blog);
    stroke: var(--day-text-color-blog);
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
    &:hover {
        fill: var(--primary-color);
        stroke: var(--primary-color);
        opacity: 1;
    }
`;

export const AuthorSocialLink = styled(IconLink)`
    cursor: pointer;
    width: 1.8rem;
    height: 1.8rem;
    margin-left: 0.7rem;
`;