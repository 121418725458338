import React from 'react';
import { AnimateEnter } from '../../App.elements';
import PrimaryBtn from '../../components/PrimaryBtn/PrimaryBtn';
import SectionHeading from '../../components/SectionHeading/SectionHeading';
import { contactData } from '../../data/app_data';
import {
    ContactHeading,
    ContactSectionContainer,
    ContactDetails,
    ContactText
} from './ContactSection.elements';

const ContactSection = ({
    isDarkMode
}) => {
    return (
        <>
            <ContactSectionContainer
                className="contact-section"
                isDarkMode={isDarkMode}
                id="contact-section"
                name="contact-section"
            >
                <SectionHeading isDarkMode={isDarkMode} text={contactData.title} />
                <ContactDetails className="contact-details">
                    <AnimateEnter>
                        <ContactHeading isDarkMode={isDarkMode}>{contactData.heading}</ContactHeading>
                    </AnimateEnter>
                    <ContactText className="contact-text">
                        <AnimateEnter>
                            {contactData.text}
                        </AnimateEnter>
                    </ContactText>
                    <AnimateEnter>
                        <PrimaryBtn text={contactData.btnText} isDarkMode={isDarkMode} url={contactData.btnUrl}/>
                    </AnimateEnter>
                </ContactDetails>
            </ContactSectionContainer>
        </>
    );
};

export default ContactSection;
