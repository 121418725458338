import React from 'react'
import { SkillBullet, SkillText, SkillWrap } from './Skill.elements';

const Skill = ({skillTitle, portfolio}) => {
    return (
        <>
            <SkillWrap className="skill" portfolio={portfolio}>
                <SkillBullet className="bullet" portfolio={portfolio}/>
                <SkillText className="skill-text" portfolio={portfolio}>{skillTitle}</SkillText>
            </SkillWrap>
        </>
    )
}

export default Skill
