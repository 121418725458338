import React from 'react'
import Loading from '../../../../components/loading/Loading'
import useGetBlogPosts from '../../../../data/apis/get_blog_posts'
import ErrorContentSection from '../../../error/ErrorContentSection'
import { BlogItem } from '../../components/blogitem'
import { BlogContentSection, BlogItemsContainer, NoContent, NoContentWrapper } from './BlogHomePage.elements'

function BlogHomePage({
  posts
}) {
  const { error, isPending, data: blogs } = useGetBlogPosts()

  
  if (isPending) return <Loading />
  if (error != null) return <ErrorContentSection/> // TODO: show error to user
  if (blogs == null) return <Loading /> // TODO: show no post found error to user

  return (
    <BlogContentSection className='blog-content'>
      <BlogItemsContainer>

        {
          blogs.length > 0 
          ? blogs.map((post) =>
          (<BlogItem
            title={post.title}
            author={post.author}
            date={post.date}
            content={post.excerpt}
            slug={post.slug}
            images={post.images} />
          ))
          : <NoContentWrapper><NoContent>No content yet!</NoContent></NoContentWrapper>
        }
      </BlogItemsContainer>
    </BlogContentSection>
  )
}

export default BlogHomePage