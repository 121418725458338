import styled from "styled-components";

import { FiX } from "react-icons/fi";

import { Link } from "react-router-dom";
import { device } from "../../../../configs/breakpoints";


export const BlogMenuContainer = styled.div`
    position: absolute;
    top: 0px;
    right: ${props => props.isClosed ? "-100%" : "0px"};
    height: 100vh;
    width: 90%;
    background-color: var(--primary-color);
    z-index: 1000;
    transition: all 0.5s ease-in-out;
    box-shadow: 0px 0px 12px rgba(0,0,0,0.3);

    @media ${device.tablet} {
        position: static;
        height: fit-content;
        width: 80%;
        background-color: transparent;
        z-index: auto;
        box-shadow: none;
    }
`;


export const BlogMenuItems = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 48px;

    @media ${device.tablet} {
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 0px;
        padding-right: 24px;
    }
`;


export const BlogMenuItem = styled(Link)`
    color: var(--day-text-color);
    width: 100%;
    text-align: center;
    padding: 24px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    opacity: 0.6;
    cursor: none;
    transition: all 0.3s ease-in-out;
    &:hover {
        cursor: pointer;
        opacity: 1;
        /* & div {
            color: var(--primary-color);
        } */
    }
    @media ${device.tablet} {
        width: 100px;
    }

    @media ${device.laptopS} {
        margin: auto 2%;
    }
`;



export const BlogMenuText = styled.div`
    font-size: 14px;
    transition: all 0.5s ease-in-out;
    text-transform: uppercase;
`;

export const CloseBlogMenuLink = styled.button`
    color: white;
    width: 36px;
    height: 36px;
    margin: 16px;
    padding: 0px;
    border: 3px solid white;
    background-color: transparent;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media ${device.tablet} {
        display: none;
    }
`;

export const CloseIcon = styled(FiX)`
    width: 32px;
    height: 32px;
`;
