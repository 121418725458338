import styled from 'styled-components';
import { FiGithub, FiExternalLink } from 'react-icons/fi';
import Photo from '../../components/Photo/Photo';

export const ItemContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 64px;
`;

export const WorkImage = styled(Photo)`

`;
export const WorkCard = styled.div`
    background-color: ${props => props.isDarkMode ? "var(--night-card-surface-color)" : "var(--day-card-surface-color)"};
    padding: 100px 7.5% 24px 7.5%;
    width: 100%;
    position: relative;
    bottom: 84px;
    border-radius: 16px;
    transition: background-color 0.5s ease-in-out;
`;

export const WorkTitle = styled.h3`
    font-family: var(--heading-font);
    font-size: 1.3rem;
    color: ${props => props.isDarkMode ? "var(--night-text-color)" : "var(--day-text-color)"};
    transition: color 0.5s ease-in-out;
`;

export const TechUsedContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

`;

export const ProjectLink = styled.a`
    
`;

export const Icons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 30%;
    margin-top: 16px;
`;

const iconStyles = `
    width: 24px;
    height: 24px;
    color: var(--primary-color);
    transition: all 0.5s ease-in-out;

    &:hover {
        fill: var(--primary-color);
    }

`;

export const GithubIcon = styled(FiGithub)`
    ${iconStyles}
    /* fill: ${props => props.isDarkMode ? "transparent" : "var(--primary-color)"}; */
`;

export const OtherSiteIcon = styled(FiExternalLink)`
    ${iconStyles}
    /* fill: ${props => props.isDarkMode ? "transparent" : "var(--primary-color)"} */
`;