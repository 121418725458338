import ProfileImageUrl from '../assets/images/prabhat-pandey-image.webp';
import DashedImageUrl from '../assets/images/dashed_border_image.svg';
import BattleZoneImage from '../assets/images/feature_img_1.webp';
import CurrencyConverterImage from '../assets/images/feature_img_2.webp';
import WallpapersProImage from '../assets/images/feature_img_3.webp';
import SmartBuyerImage from '../assets/images/feature_img_4.webp';
import Covid19Image from '../assets/images/feature_img_5.webp';
import BancMania from '../assets/images/feature_img_6.webp';
import HomeIconSrc from '../assets/images/home.svg';
import AboutIconSrc from '../assets/images/about.svg';
import WorksIconSrc from '../assets/images/work.svg';
import ContactIconSrc from '../assets/images/contact.svg';

export const globalData = {
    dashedImageUrl: DashedImageUrl
};


export const appBarData = {
    menuItems: [
        {
            title: "Home",
            iconSrc: HomeIconSrc,
            iconKey: 'home',
            itemId: 'home-section'
        },
        {
            title: "About",
            iconSrc: AboutIconSrc,
            iconKey: 'about',
            itemId: 'about-section'
        },
        {
            title: "Works",
            iconSrc: WorksIconSrc,
            iconKey: 'works',
            itemId: 'works-section'
        },
        {
            title: "Contact",
            iconSrc: ContactIconSrc,
            iconKey: 'contact',
            itemId: 'contact-section'
        },

    ]
};

export const socialBarData = {
    socialLinks: [
        {
            title: "Facebook",
            key: "fb",
            url: "https://facebook.com/prabhatsdp"
        },

        {
            title: "Twitter",
            key: "twitter",
            url: "https://twitter.com/prabhatsdp"
        },
        {
            title: "LinkedIn",
            key: "linkedin",
            url: "https://www.linkedin.com/in/prabhatsdp/"
        },
        {
            title: "Github",
            key: "github",
            url: "https://github.com/prabhatsdp"
        },

    ]
};

export const homeData = {
    greetingText: "Hi, I am",
    introName: "Prabhat Pandey,",
    introProfession: "an Android App Developer.",
    introDetails: `I am an Android Developer having 3+ years of
                    experience in building fast, reliable, scalable, maintainable
                    and secure Android apps using Kotlin. Currently, I am working as a Sr. Associate Consultant - Android at Oodles Technologies, Gurugram.`,
    homeBtnText: "Connect With Me",
    homeBtnUrl: "mailto:prabhatsdp@gmail.com"
};

export const aboutData = {
    pageTitle: "About",
    aboutText: [
        {
            text: `Hello, my name is Prabhat Pandey and I am a self-taught
         Android App Developer. I love developing and occasionally
         design apps for Android platform. I have been doing Android
         application development for more than three
         years. I am currently working as a 
         Senior Associate Consultant - Android at Oodles Technologies`,
            key: "0"
        },
        {
            text: `It was the year 2017 when I started learning programming
            in Java and soon after that I picked Android app development
            as an option to go ahead with. I developed my first
            hobby Android app in 2018 and since then, I have developed
            many scalable and secure apps for my clients.
            I also occasionally develop Web Apps
            using latest technologies.`,
            key: "1"
        },
        {
            text: `My main focus is to build fast, reliable, secure,
            scalable and maintainable apps that provide
            the best digital experience to the users.`,
            key: "2"
        },
        {
            text: `Currently, I am working as a Senior Associate Consultant - Development(Android) at Oodles Technologies, Gurugram.`,
            key: "3"
        },
        {
            text: `Here are a few things I am
            skilled and experienced at:`,
            key: "4"
        }
    ],

    skills: [
        "Kotlin",
        "Git",
        "Java",
        "Android Studio",
        "Android SDK",
        "REST APIs",
        "MVVM",
        "XML",
        "SQLite & MySQL DB",
        "HTML, CSS & JavaScript",
        "Firebase",
        "React",
        "Room Database",
        "Node",
    ],

    profileImageUrl: ProfileImageUrl,
    imageAltText: "Prabhat Pandey"
};

export const portfolioData = {
    title: "Some Things I've Built",
    works: [
        {
            imageUrl: BattleZoneImage,
            imageAltText: "BattleZone App Image by Prabhat Pandey",
            title: "BattleZone",
            tech: ["Kotlin", "Firebase", "PayU Payment Gateway"],
            githubLink: null,
            siteLink: "https://battlezone.in",
        },
        {
            imageUrl: CurrencyConverterImage,
            imageAltText: "CurrencyConverter App Image by Prabhat Pandey",
            title: "CurrencyConverter",
            tech: ["Kotlin", "Retrofit", "REST API", "MVVM"],
            githubLink: "https://github.com/prabhatsdp/CurrencyConverterAndroid",
            siteLink: null,
        },
        {
            imageUrl: WallpapersProImage,
            imageAltText: "WallpapersPro App Image by Prabhat Pandey",
            title: "WallpapersPRO",
            tech: ["Java", "Firebase", "Material Design"],
            githubLink: null,
            siteLink: "https://play.google.com/store/apps/details?id=com.prabhat.wallpaperspro",
        },
        {
            imageUrl: Covid19Image,
            imageAltText: "Covid19India App Image by Prabhat Pandey",
            title: "COVID19 Tracker India",
            tech: ["Flutter", "BLOC", "FL Chart", "Flare"],
            githubLink: "https://github.com/prabhatsdp/covid19-flutter",
            siteLink: "https://battlezone.in",
        },
        {
            imageUrl: BancMania,
            imageAltText: "BancMania App Image by Prabhat Pandey",
            title: "BancMania",
            tech: ["Java", "Firebase", "Notifications"],
            githubLink: null,
            siteLink: "https://play.google.com/store/apps/details?id=in.crazybytes.bankmania",
        },
        {
            imageUrl: SmartBuyerImage,
            imageAltText: "SmartBuyer App Image by Prabhat Pandey",
            title: "SmartBuyer",
            tech: ["Java", "WebView"],
            githubLink: null,
            siteLink: "https://play.google.com/store/apps/details?id=in.crazybytes.smartbuyer",
        }


    ]
};

export const contactData = {
    title: "Connect With Me",
    heading: "Let's Talk!",
    text: "I always like to work on new and exciting freelance projects and help people. I you have a project you want to discuss or any other question, please drop me a message.",
    btnText: "Say Hello",
    btnUrl: "mailto:prabhatsdp@gmail.com"
};

export const footerData = {
    footerText: "Designed & Built by Prabhat Pandey \n using ReactJS."
};