import styled from 'styled-components';

export const SkillWrap = styled.div`
    width: ${props => props.portfolio ? null : "48%"};
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    margin-top: 8px;
    margin-left: ${props => props.portfolio ? "16px" : null};

    &:first-child {
        margin-left: ${props => props.portfolio ? "0px" : null};
    }
`;

export const SkillBullet = styled.div`
    width: ${props => props.portfolio ? "4px" : "8px"};
    height: ${props => props.portfolio ? "4px" : "8px"};
    min-width: ${props => props.portfolio ? "4px" : "8px"};
    min-height: ${props => props.portfolio ? "4px" : "8px"};
    background: var(--primary-color);
    border-radius: 4px;
    margin-top: 8px;
`;

export const SkillText = styled.span`
    margin-left: ${props => props.portfolio ? "8px" : "16px"};
    font-size: ${props => props.portfolio ? "14px" : "16px"};
`;