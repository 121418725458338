import styled from 'styled-components';
import { ReactComponent as Logo } from '../../assets/images/site_logo.svg';
import {HiMenuAlt3} from 'react-icons/hi'
import { device } from '../../configs/breakpoints';
import { Link } from 'react-router-dom';

export const AppBarContainer = styled.div`
    width: 100%;
    height: ${ props => props.fixed ? "56px" : "84px"};
    background: ${ props => props.fixed ? (props.isDarkMode ? "var(--night-card-surface-color)" : "white") : "transparent"};
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 24px;
    position: fixed;
    z-index: 999;
    transition: height 0.3s ease-in-out, background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, ${props => props.isDarkMode ? (props.fixed ? "0.25" : "0.0") : (props.fixed ? "0.15" : "0.0")});

    @media ${device.tablet} {
        padding: 0 48px;
    }
`;

export const SiteLogoContainer = styled(Link)`
    height: 32px;
    width: 32px;
`;

export const SiteLogo = styled(Logo)`
    width: 100%;
    height: 100%;
`;

export const NavMenuIcon = styled(HiMenuAlt3)`
    width: 32px;
    height: 32px;
    margin-left: 16px;
    color: var(--primary-color);
    @media ${device.tablet} {
        display: none;
    }
`;

export const BlogButton = styled(Link)`
    padding-right: 1rem;
    padding-left: 1rem;
    height: 2rem;
    padding-bottom: 0.1rem;
    background-color: var(--primary-color);
    color: var(--night-surface-color-dark);
    margin-right: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    border-radius: 0.5rem;
    transition: all 0.3s ease-in-out;
    &:hover {
        transform: rotate(3deg) scale(110%);
    }
`;



