
import React from 'react'
import Loading from '../../../../components/loading/Loading'
import useGetReadingTime from '../../../../domain/usecases/get_reading_time'
import { ArticleDetailsItem } from './Author.elements'

const ReadingTime = ({content}) => {

    const {error, isPending, data: details} = useGetReadingTime(content)

    if (isPending) return <div></div>
    if (details == null) return <div></div>
    if (error != null) return <ArticleDetailsItem>{error}</ArticleDetailsItem>

  return ( <ArticleDetailsItem>{details.text}</ArticleDetailsItem> )
}

export default ReadingTime