import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const KharchaHomeContainer = styled.div`
    padding: 5% 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #F7F7F7;
`

export const KharchaCard = styled.div`
    border-radius: 50px;
    padding: 50px;
    display: flex;
    background-color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const KharchaMainHeading = styled.h1`
    margin-bottom: 20px;
    margin-top: 10px;
    text-align: center;
`

export const KharchaText = styled.p`
    text-align: center;
`

export const KharchaLogo = styled.img`
    width: 78px;
    height: 78px;
    border-radius: 10%;
    object-fit: cover;
`;

export const KharchaLink = styled(Link)`
    color: #9AF906;
    margin-top: 20px;
    font-size: 12px;
    font-weight: bold;
    &:hover {
        cursor: pointer;
    }
`