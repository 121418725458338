import React, { useEffect, useRef } from 'react'

import { useParams } from 'react-router-dom'
import Loading from '../../../../components/loading/Loading'
import useFetchPostBySlug from '../../../../data/apis/use_fetch_data'
import { BlogPostContainer } from './BlogPost.elements'
import BlogSection from './BlogSection'
import BlogPostMeta from '../../../../components/metas/BlogPostMeta'
import ScrollToTop from '../../components/utils/ScrollToTop'
import ErrorContentSection from '../../../error/ErrorContentSection'

const BlogPost = () => {
    let isMounted = useRef(false)
    const params = useParams()
    useEffect(() => {
        isMounted.current = true
      console.log("Blog Post Mounted====> "+isMounted.current)
      return () => {
        isMounted.current = false        
      }
    }, [params.slug])
    
    const { error, isPending, data: postData } = useFetchPostBySlug(params.slug)
    console.log("Bog Post ===> ", postData)
    if (isPending) return <Loading />
    if (error != null) return <ErrorContentSection/> // TODO: show error to user
    if (postData == null) return <Loading /> // TODO: show no post found error to user
    if (postData.post == null) return <Loading /> // TODO: show no post found error to user

    return (
        <>
            <ScrollToTop/>
            <BlogPostMeta metaDetails={postData.meta}/>
            <BlogPostContainer className='blog-post-container'>
                <BlogSection post={postData.post} />
            </BlogPostContainer>
        </>
    )
}

export default BlogPost