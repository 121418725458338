
import React from 'react'
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton } from 'react-share'
import { SocialLinkItem, SocialShareHeading, SocialShareItems, SocialShareWrapper } from './SocialShare.elements'

const SocialShare = () => {
    return (
        <>
            <SocialShareWrapper>
                <SocialShareHeading>Share this post</SocialShareHeading>
                <SocialShareItems>

                    <SocialLinkItem>
                        <FacebookShareButton url={window.location.href} >
                            <FacebookIcon round size={"1.8rem"}></FacebookIcon>
                        </FacebookShareButton>
                    </SocialLinkItem>


                    <SocialLinkItem>
                        <TwitterShareButton url={window.location.href} >
                            <TwitterIcon round size={"1.8rem"}></TwitterIcon>
                        </TwitterShareButton>
                    </SocialLinkItem>

                    <SocialLinkItem>
                        <LinkedinShareButton url={window.location.href} >
                            <LinkedinIcon round size={"1.8rem"}></LinkedinIcon>
                        </LinkedinShareButton>
                    </SocialLinkItem>

                </SocialShareItems>
            </SocialShareWrapper>
        </>
    )
}

export default SocialShare