import React from 'react'
import { footerData } from '../../data/app_data'
import { FooterContainer, FooterText, HomeFooterLinks } from './Footer.elements';
import { Link } from 'react-router-dom';

const Footer = ({
    isDarkMode
}) => {
    return (
        <>
            <FooterContainer isDarkMode={isDarkMode} className="footer-container">
                <HomeFooterLinks darkmode={isDarkMode}>
                    <Link to={"/privacy"}>Privacy</Link>
                    <Link to={"/"} >About</Link>
                    <Link to={"/blog"} >Blog</Link>
                </HomeFooterLinks>
                <FooterText className="footer-text">
                    {footerData.footerText}
                </FooterText>
            </FooterContainer>
        </>
    )
}

export default Footer
