const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tabletS: '700px',
    tablet: '769px',
    tabletL: '900px',
    laptopS: '1025px',
    laptop: '1200px',
    laptopL: '1401px',
    desktop: '2560px'
}

export const device = {
    mobileS: `only screen and (max-width: ${size.mobileS})`,
    mobileM: `only screen and (max-width: ${size.mobileM})`,
    mobileL: `only screen and (max-width: ${size.mobileL})`,
    tablet: `only screen and (min-width: ${size.tabletS})`,
    tabletL: `only screen and (min-width: ${size.tabletL})`,
    laptopS: `only screen and (min-width: ${size.tablet})`,
    laptop: `only screen and (min-width: ${size.laptopS})`,
    laptopL: `only screen and (min-width: ${size.laptop})`,
    desktop: `only screen and (min-width: ${size.laptopL})`,
}
