import styled from 'styled-components';
import { Section } from '../../App.elements';


export const ContactSectionContainer = styled(Section)`
    padding-top: 120px;
    height: 75vh;
    margin-bottom: 48px;
`;

export const ContactDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
`;

export const ContactHeading = styled.h2`
    font-size: 3rem;
    color: ${props => props.isDarkMode ? "var(--night-text-color)" : "var(--day-text-color)"}
`;

export const ContactText = styled.p`
    text-align: center;
    margin-top: 24px;
    font-size: 16px;
    width: 60%;
`;