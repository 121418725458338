import React, { useEffect } from 'react';
import { scrollSpy, Events } from 'react-scroll';
import Photo from '../../components/Photo/Photo';
import SectionHeading from '../../components/SectionHeading/SectionHeading';
import Skill from '../../components/Skill/Skill';
import { aboutData } from '../../data/app_data';
import {
    AboutContainer,
    AboutDetails,
    AboutImageContainer,
    AboutText,
    AboutTextContainer,
    SkillsColumn,
    SkillsContainer
} from './AboutSection.elements';
import { AnimateEnter } from '../../App.elements';

const AboutSection = ({ isDarkMode }) => {



    useEffect(() => {
        Events.scrollEvent.register("begin", function (to, element) {
            console.log("begin", arguments);
        });

        Events.scrollEvent.register("end", function (to, element) {
            console.log("end", arguments);
        });

        scrollSpy.update();
        return () => {
            Events.scrollEvent.remove("begin");
            Events.scrollEvent.remove("end");
        };
    });

    return (
        <>
            <AboutContainer className="about-section" isDarkMode={isDarkMode} id="about-section" name="about-section">
                <SectionHeading className="section-heading" isDarkMode={isDarkMode} text={aboutData.pageTitle} />
                <AboutDetails isDarkMode={isDarkMode} className="about-details">
                    <AboutTextContainer>

                        {aboutData.aboutText.map((item) =>
                        (
                            <AnimateEnter>
                                <AboutText key={item.key}>
                                    {item.text}
                                </AboutText>
                            </AnimateEnter>
                        )
                        )}
                        <AnimateEnter>
                            <SkillsContainer className="skills-container">
                                <SkillsColumn className="skills-column">

                                    {aboutData.skills.map((skill) => (
                                        <Skill skillTitle={skill} key={skill} />
                                    ))}

                                </SkillsColumn>
                            </SkillsContainer>
                        </AnimateEnter>
                    </AboutTextContainer>

                    <AboutImageContainer className="about-image-container">
                        <AnimateEnter>
                            <Photo imageUrl={aboutData.profileImageUrl} altText={aboutData.imageAltText}/>
                        </AnimateEnter>
                    </AboutImageContainer>

                </AboutDetails>
            </AboutContainer>
        </>
    );
};

export default AboutSection;
