import React from 'react'
import { DashedBg, MainPhoto, PhotoContainer, PhotoOverlay } from './Photo.elements';
import DashedImage from '../../assets/images/dashed_border_image.svg'

const Photo = ({
    width,
    height,
    imageUrl,
    showOverlay,
    isDarkMode,
    portfolio,
    altText
}) => {
    return (
        <>
            <PhotoContainer className="photo-container" portfolio={portfolio} height={height}>
                <MainPhoto className="main-photo" src={imageUrl} width={width} height={height} alt={altText} />
                <DashedBg className="dash-image" src={DashedImage}/>
                {showOverlay ? (<PhotoOverlay className="photo-overlay" isDarkMode={isDarkMode}/>) : (null)}
            </PhotoContainer>
        </>
    )
}

export default Photo
