import styled from "styled-components";

export const FooterContainer = styled.section`
    height: auto;
    width: 100%;
    background: var(--night-surface-color-dark);
    color: var(--night-text-color);
    transition: background 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
`;

export const FooterText = styled.p`
    text-align: center;
    font-size: 14px;
    width: 230px;
`;
export const FooterLinks = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    & a {
        font-size: 0.9rem;
        font-weight: 600;
        margin: 0rem 1rem 0.5rem 1rem;
        padding: 0.2rem 0.5rem;
        color: var(--primary-color);
        cursor: pointer;
        transition: 0.3s;
        border-bottom: none;
        &:hover {
            color: var(--night-text-color);
        }
    }
`;