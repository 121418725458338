import styled from "styled-components";

import { device } from "../../../../configs/breakpoints";

export const BlogContentSection = styled.section`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 4rem 2rem;
    background-color: #fcfffc;
    min-height: 100vh;
`;

export const BlogItemsContainer = styled.div`
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
`;

export const NoContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const NoContent = styled.p`
    
`;
