import React from 'react';

import { socialBarData } from '../../data/app_data';

import {
    FacebookIcon,
    GithubIcon,
    LinkedInIcon,
    SocialBarContainer,
    SocialIcons,
    TwitterIcon,
    IconLink
} from './SocialBar.elements';

const SocialBar = ({ isDarkMode }) => {


    const getIcon = (key) => {
        switch (key) {
            case 'fb':
                return (<FacebookIcon isDarkMode={isDarkMode} className={`icon ${key}-icon`} />);
            case 'twitter':
                return (<TwitterIcon isDarkMode={isDarkMode} className={`icon ${key}-icon`} />);
            case 'linkedin':
                return (<LinkedInIcon isDarkMode={isDarkMode} className={`icon ${key}-icon`} />);
            case 'github':
                return (<GithubIcon isDarkMode={isDarkMode} className={`icon ${key}-icon`} />);
            default:
                break;
        }
    };

    return (
        <>
            <SocialBarContainer className="social-bar-container" isDarkMode={isDarkMode}>

                <SocialIcons>

                    {socialBarData.socialLinks.map((link) => (

                        <IconLink href={link.url} target='_blank' rel='noreferrer' className={`social-link ${link.key}-link`}>
                            {getIcon(link.key)}
                        </IconLink>

                    ))}

                </SocialIcons>
            </SocialBarContainer>
        </>
    );
};

export default SocialBar;
