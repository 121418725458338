import React from 'react';
import { AnimateEnter } from '../../App.elements';

import { HeadingSpan, HeadingText } from './SectionHeading.elements';

const SectionHeading = ({ text, isDarkMode }) => {
    return (
        <>
            <AnimateEnter>
                <HeadingText isDarkMode={isDarkMode}>
                    <HeadingSpan> {text} </HeadingSpan>
                </HeadingText>
            </AnimateEnter>
        </>
    );
};

export default SectionHeading;
