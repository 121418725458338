import React, { useState, useEffect } from 'react'

import { appBarData } from '../../../../data/app_data';

import { Spacer } from '../../../../App.elements';
import NightModeBtn from '../../../../components/NightModeBtn/NightModeBtn';
import { AppBarContainer, SiteLogoContainer, SiteLogo, NavMenuIcon } from '../../../../sections/AppBar/AppBar.elements'
import NavMenu from '../../../../components/NavMenu/NavMenu';
import {
    AuthorSocial,
    BlogAppBarContainer,
    BlogLogo,
    BlogName,
    BlogTitle,
    AuthorSocialLink,
    Facebook,
    LinkedIn,
    Twitter,
    GitHub,
    BlogAppBarContent
} from './BlogAppBar.elements';
import BlogMenu from '../menu/BlogMenu';


function BlogAppBar() {

    const [fixed, setFixed] = useState(false);
    const [isNavClosed, setNavClosed] = useState(true);

    const closeNav = () => setNavClosed(true);
    const openNav = () => setNavClosed(false);


    useEffect(() => {

        let ticking = false;

        const updateFixed = () => {
            if (window.scrollY > 10) {
                setFixed(true);
            } else {
                setFixed(false);
            }
            ticking = false;
        };

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateFixed);
                ticking = true;
            }
        };

        window.addEventListener("scroll", onScroll);
        console.log(`Ticking is ${ticking}`);

        return () => window.removeEventListener("scroll", onScroll);
    }, [fixed, setFixed]);


    return (
        <>
            <BlogAppBarContainer className="app-bar-container fixed" fixed={fixed} isDarkMode={false}>
                <BlogAppBarContent>
                <BlogTitle className="blog-logo-container" to="/">
                    <BlogLogo className="blog-logo" fixed={fixed} />
                    {/* <BlogName fixed={fixed}>Prabhat</BlogName> */}
                </BlogTitle>
                <Spacer />
                {/* <NavMenu
                    menuItems={appBarData.menuItems}
                    isDarkMode={false}
                    isClosed={isNavClosed}
                    onClose={closeNav}
                /> */}
                {/* <BlogMenu isClosed={isNavClosed} onClose={closeNav}/> */}
                {/* <NightModeBtn onNightModeChanged={onNightModeChanged} /> */}
                {/* <NavMenuIcon onClick={openNav} /> */}
                <AuthorSocial>
                    <AuthorSocialLink href='https://facebook.com/prabhatsdp' target='_blank'>
                        <Facebook isDarkMode={false} className={`icon social-icon`} />
                        </AuthorSocialLink>
                    <AuthorSocialLink href='https://twitter.com/prabhatsdp' target='_blank'>
                        <Twitter isDarkMode={false} className={`icon social-icon`} />
                        </AuthorSocialLink>
                    <AuthorSocialLink href='https://linkedin.com/in/prabhatsdp' target='_blank'>
                        <LinkedIn isDarkMode={false} className={`icon social-icon`} />
                        </AuthorSocialLink>
                    <AuthorSocialLink href='https://github.com/prabhatsdp' target='_blank'>
                        <GitHub isDarkMode={false} className={`icon social-icon`} />
                        </AuthorSocialLink>
                </AuthorSocial>
                </BlogAppBarContent>
            </BlogAppBarContainer>
        </>
    )
}

export default BlogAppBar