import React from 'react'
import { Helmet } from 'react-helmet'
import PersonalImage from '../../assets/images/play_store_banner_1080.webp'

const HomeMeta = () => {
    return (
        <>
            <Helmet>
                <title>Prabhat Pandey 👨‍💻 - Android Developer</title>
                <meta name="robots" content="noindex, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Prabhat Pandey 👨‍💻 - Android Developer" />
                <meta property="og:description" content="Prabhat Pandey is a self-taught 📚 Android Developer. He has been doing Android Development for more than three years. He loves coding, developing 👨‍💻 and occasionally designing ✏️ apps for Android platform." />
                <meta property="description" content="Prabhat Pandey is a self-taught 📚 Android Developer. He has been doing Android Development for more than three years. He loves coding, developing 👨‍💻 and occasionally designing ✏️ apps for Android platform." />
                <meta property="og:url" content="https://prabhatpandey.dev/" />
                <meta property="og:site_name" content="Prabhat Pandey" />
                <meta property="og:image" content={PersonalImage} />
                <meta property="og:image:width" content="728" />
                <meta property="og:image:height" content="410" />
                <meta property="og:image:type" content="image/jpeg" />
                <meta name="author" content="Prabhat Pandey" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content="@prabhatsdp" />
                <meta name="twitter:label1" content="Profession" />
                <meta name="twitter:data1" content="Android Developer" />
                <meta name="twitter:label2" content="" />
                <meta name="twitter:data2" content="" />
            </Helmet>
        </>
    )
}

export default HomeMeta