import React from 'react'

import {BtnHoverBg, BtnLink, BtnText, BtnBase} from './PrimaryBtn.elements'

const PrimaryBtn = ({
    text,
    url,
    isDarkMode
}) => {
    return (
        <>
            <BtnLink className="btn-link" href={url}>
                <BtnBase className="btn-base" isDarkMode={isDarkMode}/>
                <BtnHoverBg className="btn-hover-bg" isDarkMode={isDarkMode}/>
                <BtnText className="btn-text" isDarkMode={isDarkMode}>
                    {text}
                </BtnText>
            </BtnLink>
        </>
    )
}

export default PrimaryBtn
