import { Link } from "react-router-dom";
import styled from "styled-components";

import { device } from "../../../../configs/breakpoints";

import { ReactComponent as Logo } from '../../../../assets/images/site_logo.svg';
import { FacebookIcon, GithubIcon, IconLink, LinkedInIcon, TwitterIcon } from "../../../../components/SocialBar/SocialBar.elements";

export const BlogAppBarContainer = styled.div`
    width: 100%;
    height: ${ props => props.fixed ? "3rem" : "4rem"};
    background: ${ props => props.fixed ? "#FCFFFC": "#FCFFFC"};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    position: fixed;
    border-bottom: 1px solid #161c2722;
    z-index: 999;
    transition: height 0.3s ease-in-out, background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, ${props => props.isDarkMode ? (props.fixed ? "0.25" : "0.0") : (props.fixed ? "0.15" : "0.0")});

    @media ${device.tablet} {
        padding: 0 24px;
        height: ${ props => props.fixed ? "2.4rem" : "3.5rem"};
    }
`;

export const BlogAppBarContent = styled.div`
    width: 100%;
    max-width: 1366px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const BlogTitle = styled(Link)`
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: white;
    align-items: center;
    transition: all 1s ease-in-out;
    &:hover {
        cursor: pointer;
    }
`;

export const BlogLogo = styled(Logo)`
    width: ${ props => props.fixed ? "1.6rem" : "2.3rem"};
    height: ${ props => props.fixed ? "1.6rem" : "2.3rem"};
    transition: all 0.3s ease-in-out;
    color: white;
    & path {
        fill: var(--day-text-color);
    }
`;

export const BlogName = styled.h1`
    display: inline;
    font-size: ${ props => props.fixed ? "1.4rem" : "1.8rem"};
    transition: all 0.3s ease-in-out;
    margin-left: 8px;
    color: var(--day-text-color);
`;


export const AuthorSocial = styled.div`
    flex-grow: 1;
    display: flex;
    margin-left: 1rem;
    flex-direction: row;
    justify-content: flex-end;
`

export const Facebook = styled(FacebookIcon)`
    cursor: pointer;
    width: 1.1rem;
    height: 1.1rem;
    fill: var(--day-text-color-blog);
    stroke: var(--day-text-color-blog);
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
    &:hover {
        fill: var(--primary-color);
        stroke: var(--primary-color);
        opacity: 1;
    }
`;
export const Twitter = styled(TwitterIcon)`
    cursor: pointer;
    width: 1.1rem;
    height: 1.1rem;
    fill: var(--day-text-color-blog);
    stroke: var(--day-text-color-blog);
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
    &:hover {
        fill: var(--primary-color);
        stroke: var(--primary-color);
        opacity: 1;
    }
`;
export const LinkedIn = styled(LinkedInIcon)`
    cursor: pointer;
    width: 1.1rem;
    height: 1.1rem;
    fill: var(--day-text-color-blog);
    stroke: var(--day-text-color-blog);
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
    &:hover {
        fill: var(--primary-color);
        stroke: var(--primary-color);
        opacity: 1;
    }
`;
export const GitHub = styled(GithubIcon)`
    cursor: pointer;
    width: 1.1rem;
    height: 1.1rem;
    fill: var(--day-text-color-blog);
    stroke: var(--day-text-color-blog);
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
    &:hover {
        fill: var(--primary-color);
        stroke: var(--primary-color);
        opacity: 1;
    }
`;

export const AuthorSocialLink = styled(IconLink)`
    cursor: pointer;
    width: 1.8rem;
    height: 1.8rem;
    margin-left: 0.7rem;
`;