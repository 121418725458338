import styled from 'styled-components';

import { Section } from '../../App.elements';

import { device } from '../../configs/breakpoints';

export const HomeContainer = styled(Section)`
    height: 100vh;
    justify-content: center;
`;

export const HomeDetails = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    justify-content: center;
    position: absolute;
`;

export const GreatingText = styled.h4`
    color: var(--primary-color);
    font-size: 1em;
    font-family: var(--heading-font);
    margin-bottom: 24px;
`;

export const IntroHeading = styled.h1`
    font-size: 2.2em;
    color: ${props => props.isDarkMode ? "var(--night-text-color)" : "var(--day-text-color)"};
    transition: color 0.5s ease-in-out;
    font-weight: ${props => props.isDarkMode ? "700" : "800"}
`;

export const IntroText = styled.p`
    font-size: 18px;
    margin-top: 24px;


    @media ${device.tablet} {
        max-width: 80%;
    }

`;