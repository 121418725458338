
import React from 'react'
import {
    ErrorContent,
    ErrorHeading,
    ErrorSubHeading,
    ErrorText,
    ButtonWrapper,
    BackHomeButton
} from './NotFound.elements'

const ErrorContentSection = () => {
    return (
        <ErrorContent>
            <ErrorHeading>404</ErrorHeading>
            <ErrorSubHeading>Page Not Found</ErrorSubHeading>
            <ErrorText>Sorry, we couldn't find the page you're looking for. Either the page doesn't exists or was removed.</ErrorText>
            <ButtonWrapper>
                <BackHomeButton>Back To Home</BackHomeButton>
                <BackHomeButton>Read Blog</BackHomeButton>
            </ButtonWrapper>
        </ErrorContent>
    )
}

export default ErrorContentSection