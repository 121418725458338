import { Link } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../configs/breakpoints";
import ErrorImage from "../../assets/images/404_2.svg"

export const ErrorPageWrapper = styled.section`
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: var(--day-text-color-blog);
`

export const ErrorContent = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 3.1rem;
    max-width: 1200px;
    width: 100%;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
`;

export const ErrorHeading = styled.h1`
    font-size: 6rem;
    background-image: url(${ErrorImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding: 1rem 6rem;
    overflow: visible;
    @media ${device.tablet} {
        font-size: 4rem;
    }
`;

export const ErrorSubHeading = styled.h3`
    font-size: 1.1rem;
    margin: 0;
`;

export const ErrorText = styled.p`
    font-size: 0.9rem;
    max-width: 50%;
    margin-top: 1rem;
    text-align: center;
    transition: all 0.3s ease-in-out;
    @media ${device.tablet} {
        max-width: 30%;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export const BackHomeButton = styled(Link)`
    background-color: var(--primary-color);
    color: var(--day-text-color-blog);
    padding: 0.7rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    margin-top: 1rem;
    transition: all 0.3s ease-in-out;
    font-size: 1rem;
    min-width: 9rem;
    text-align: center;
    margin-right: 1rem;
    margin-left: 1rem;
    font-weight: 600;
    &:hover {
        transform: rotate(3deg) scale(110%);
    }
`;