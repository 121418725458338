import React from 'react';
import PrimaryBtn from '../../components/PrimaryBtn/PrimaryBtn';
import { GreatingText, HomeContainer, IntroHeading, IntroText } from './HomeSection.elements';
import { homeData } from '../../data/app_data';
import { AnimateEnter } from '../../App.elements';

const HomeSection = ({ isDarkMode }) => {
    return (
        <>

            <HomeContainer
                className="home-container"
                id="home-section"
                name="home-section"
            >
                <AnimateEnter>
                    <GreatingText className="greeting-text">{homeData.greetingText}</GreatingText>
                </AnimateEnter>
                <AnimateEnter>
                    <IntroHeading className="intro-heading" isDarkMode={isDarkMode}>{homeData.introName}</IntroHeading>
                    <IntroHeading className="intro-heading" isDarkMode={isDarkMode}>{homeData.introProfession}</IntroHeading>
                </AnimateEnter>
                <AnimateEnter>
                    <IntroText className="intro-text">
                        {homeData.introDetails}
                    </IntroText>
                </AnimateEnter>
                <AnimateEnter>
                    <PrimaryBtn text={homeData.homeBtnText} isDarkMode={isDarkMode} url={homeData.homeBtnUrl}/>
                </AnimateEnter>

            </HomeContainer>

        </>
    );
};

export default HomeSection;
