import React, { useState, useEffect } from 'react';
import { appBarData } from "../../data/app_data";
import { Spacer } from '../../App.elements';
import NightModeBtn from '../../components/NightModeBtn/NightModeBtn';
import { AppBarContainer, BlogButton, NavMenuIcon, SiteLogo, SiteLogoContainer } from './AppBar.elements';
import NavMenu from '../../components/NavMenu/NavMenu';

const AppBar = ({ onNightModeChanged, isDarkMode }) => {

    const [fixed, setFixed] = useState(false);
    const [isNavClosed, setNavClosed] = useState(true);

    const closeNav = () => setNavClosed(true);
    const openNav = () => setNavClosed(false);

    useEffect(() => {

        let ticking = false;

        const updateFixed = () => {
            if (window.scrollY > 20) {
                setFixed(true);
            } else {
                setFixed(false);
            }
            ticking = false;
        };

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateFixed);
                ticking = true;
            }
        };

        window.addEventListener("scroll", onScroll);
        console.log(`Ticking is ${ticking}`);

        return () => window.removeEventListener("scroll", onScroll);
    }, [fixed, setFixed]);

    return (
        <>
            <AppBarContainer className="app-bar-container fixed" fixed={fixed} isDarkMode={isDarkMode}>
                <SiteLogoContainer className="site-logo-container" to="/">
                    <SiteLogo className="site-logo" />
                </SiteLogoContainer>
                <Spacer />
                <NavMenu
                    menuItems={appBarData.menuItems}
                    isDarkMode={isDarkMode}
                    isClosed={isNavClosed}
                    onClose={closeNav}
                />
                <BlogButton className='blog-btn' to={'/blog'}>Blog</BlogButton>
                <NightModeBtn onNightModeChanged={onNightModeChanged} />
                <NavMenuIcon onClick={openNav} />
            </AppBarContainer>
        </>
    );
};

export default AppBar;
