import React, { useState } from 'react';

import { BtnBackground, BtnSlider, MoonIcon, SunIcon } from './NightModeBtn.elements';

const NightModeBtn = ({ onNightModeChanged }) => {

    const [isNightMode, setIsNightMode] = useState(true);

    const _onNightModeChanged = (status) => {
        setIsNightMode(status);
        onNightModeChanged(status);
    };

    return (
        <>
            <BtnBackground
                isNightMode={isNightMode}
                onClick={() =>
                    _onNightModeChanged(!isNightMode)}
                className="slider-btn-bg"
                to=""
            >
                <BtnSlider isLeft={isNightMode} className="btn-slider" />
                <SunIcon className="sun-icon" />
                <MoonIcon className="moon-icon" />
            </BtnBackground>
        </>
    );
};

export default NightModeBtn;
