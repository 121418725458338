import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { AppContainer } from '../../App.elements'
import BlogAppBar from './components/blogappbar/BlogAppBar'
import { Outlet } from 'react-router-dom'
import BlogHomeMeta from '../../components/metas/BlogHomeMeta'
import Footer from '../../sections/Footer/Footer'
import BlogFooter from './components/blogfooter/BlogFooter'
import ScrollToTop from './components/utils/ScrollToTop'

function BlogHome() {
  return (
    <AppContainer className="App" isDarkMode={false} isBrowser={false} blog>
      <ScrollToTop />
      <BlogHomeMeta />
      <BlogAppBar />
        <Outlet />
      <BlogFooter/>
    </AppContainer>
  )
}

export default BlogHome