import { useEffect, useRef, useState } from 'react';
import { Dot, DotOutline } from './MyCustomCursor.elements';
import classNames from 'classnames';

const MyCustomCursor = ({ isDarkMode }) => {


    const [hovering, setHovering] = useState(false);

    const delay = 5;

    const dot = useRef(null);
    const dotOutline = useRef(null);

    const cursorVisible = useRef(true);
    const cursorClicked = useRef(false);
    const isMounted = useRef(false)

    const endX = useRef(window.innerWidth / 2);
    const endY = useRef(window.innerHeight / 2);
    const _x = useRef(0);
    const _y = useRef(0);

    const requestRef = useRef(null);

    useEffect(() => {
        isMounted.current = true
        document.addEventListener('mousedown', mouseDownEvent);
        document.addEventListener('mouseup', mouseUpEvent);
        document.addEventListener('mousemove', mouseMoveEvent);
        document.addEventListener('mouseenter', mouseEnterEvent);
        document.addEventListener('mouseleave', mouseLeaveEvent);

        handleLinkHoverEvents();

        animateDotOutline();

        return () => {
            isMounted.current = false
            document.removeEventListener('mousedown', mouseDownEvent);
            document.removeEventListener('mouseup', mouseUpEvent);
            document.removeEventListener('mousemove', mouseMoveEvent);
            document.removeEventListener('mouseenter', mouseEnterEvent);
            document.removeEventListener('mouseleave', mouseLeaveEvent);

            cancelAnimationFrame(requestRef.current);
        };
    });

    const handleLinkHoverEvents = () => {
        if (!isMounted) return
        document.querySelectorAll("a").forEach(el => {
            el.addEventListener("mouseover", mouseOverEvent);
            el.addEventListener("mouseout", mouseOutEvent);
        });
    };

    const toggleCursorVisibility = () => {
        if (!isMounted) return
        if (cursorVisible.current && dot.current && dotOutline.current) {
            if (cursorVisible.current) {
                dot.current.style.opacity = 1;
                dotOutline.current.style.opacity = 1;
            } else {
                dot.current.style.opacity = 0;
                dotOutline.current.style.opacity = 0;
            }
        }
    };

    const toggleCursorClicked = () => {
        if (!isMounted) return
        if (cursorVisible.current && dot.current && dotOutline.current) {
            if (cursorClicked.current) {
                dot.current.style.transform = 'translate(-50%, -50%) scale(1.25)';
                dotOutline.current.style.transform = 'translate(-50%, -50%) scale(0.75)';
            } else {
                dot.current.style.transform = 'translate(-50%, -50%) scale(1)';
                dotOutline.current.style.transform = 'translate(-50%, -50%) scale(1)';
            }
        }
    };


    const mouseOverEvent = () => {
        if (!isMounted) return
        setHovering(true);
    };

    const mouseOutEvent = () => {
        if (!isMounted) return
        setHovering(false);

    };

    const mouseDownEvent = () => {
        if (!isMounted) return
        cursorClicked.current = true;
        toggleCursorClicked();
    };

    const mouseUpEvent = () => {
        if (!isMounted) return
        cursorClicked.current = false;
        toggleCursorClicked();
    };

    const mouseEnterEvent = () => {
        if (!isMounted) return
        cursorVisible.current = true;
        toggleCursorVisibility();
    };

    const mouseLeaveEvent = () => {
        if (!isMounted) return
        cursorVisible.current = false;
        toggleCursorVisibility();
    };

    const mouseMoveEvent = e => {
        if (!isMounted) return
        cursorVisible.current = true;
        toggleCursorVisibility();
        endX.current = e.clientX;
        endY.current = e.clientY;
        if (dot.current) {
            dot.current.style.top = endY.current + 'px';
            dot.current.style.left = endX.current + 'px';
        }
    };

    const animateDotOutline = () => {
        if (!isMounted) return
        _x.current += (endX.current - _x.current) / delay;
        _y.current += (endY.current - _y.current) / delay;

        if (dotOutline.current) {
            dotOutline.current.style.top = _y.current + 'px';
            dotOutline.current.style.left = _x.current + 'px';
        }

        requestRef.current = requestAnimationFrame(animateDotOutline);
    };

    const cursorClasses = classNames(
        'cursor-dot-outline',
        {
            'hovering': hovering,
        }
    );

    return (
        <>
            <DotOutline ref={dotOutline} className={cursorClasses} isDarkMode={isDarkMode}></DotOutline>
            <Dot ref={dot} className="cursor-dot" isDarkMode={isDarkMode}></Dot>
        </>
    );
};

export default MyCustomCursor;