import React from 'react'

import { useState } from 'react';

import { AppContainer } from '../../App.elements';
import SocialBar from '../../components/SocialBar/SocialBar';
import AppBar from '../../sections/AppBar/AppBar';
import HomeSection from '../../sections/HomeSection/HomeSection';
import AboutSection from '../../sections/AboutSection/AboutSection';
import PortfolioSection from '../../sections/PortfolioSection/PortfolioSection';
import ContactSection from '../../sections/ContactSection/ContactSection';
import Footer from '../../sections/Footer/Footer';
// import BgParticles from '../../components/BgParticles/BgParticles';
import MyCustomCursor from '../../components/MyCustomCursor/MyCustomCursor';
import { isBrowser } from 'react-device-detect';
import ScrollToTop from '../blog/components/utils/ScrollToTop';
import HomeMeta from '../../components/metas/HomeMeta';

function Home() {


    const [isDarkMode, setIsDarkMode] = useState(true);


    const _onNightModeChanged = (status) => {
        setIsDarkMode(status);
      };

    const cursor = isBrowser ? <MyCustomCursor isDarkMode={isDarkMode} /> : null



    return (
        <AppContainer className="App" isDarkMode={isDarkMode} isBrowser={isBrowser}>
            <HomeMeta />
            <ScrollToTop/>
            {cursor}
            {/* <BgParticles isDarkMode={isDarkMode} /> */}
            <AppBar onNightModeChanged={_onNightModeChanged} isDarkMode={isDarkMode} />
            <SocialBar className="social-bar" isDarkMode={isDarkMode} />
            <HomeSection isDarkMode={isDarkMode} />
            <AboutSection isDarkMode={isDarkMode} />
            <PortfolioSection isDarkMode={isDarkMode} />
            <ContactSection isDarkMode={isDarkMode} />
            <Footer isDarkMode={isDarkMode} />
        </AppContainer>
    )
}

export default Home