
import React from 'react'
import BlogAppBar from '../blog/components/blogappbar/BlogAppBar'
import BlogFooter from '../blog/components/blogfooter/BlogFooter'
import ErrorContentSection from './ErrorContentSection'
import { BackHomeButton, ButtonWrapper, ErrorContent, ErrorHeading, ErrorPageWrapper, ErrorSubHeading, ErrorText } from './NotFound.elements'

const NotFound = () => {
    return (
        <>
            <ErrorPageWrapper>
                <BlogAppBar />
                    <ErrorContentSection />
                <BlogFooter />
            </ErrorPageWrapper>
        </>
    )
}

export default NotFound