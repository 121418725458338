import React, {useEffect} from 'react';
import {scrollSpy, Events} from 'react-scroll'
import { appBarData } from '../../data/app_data';

import {
    NavItem,
    NavMenuContainer,
    NavMenuItems,
    NavText,
    HomeIcon,
    AboutIcon,
    WorkIcon,
    ContactIcon,
    CloseIcon,
    CloseIconLink,
    NavIcon,
} from './NavMenu.elements';



const NavMenu = (
    {
        isDarkMode,
        menuItems,
        isClosed,
        onClose
    }) => {

    const icons = {
        home: (<HomeIcon />),
        about: (<AboutIcon />),
        works: (<WorkIcon />),
        contact: (<ContactIcon />),
    };

    // const itemIds = menuItems.map((item) => item.itemId);

    useEffect(() => {
        Events.scrollEvent.register("begin", function (to, element) {
            console.log("begin", arguments);
        });

        Events.scrollEvent.register("end", function (to, element) {
            console.log("end", arguments);
        });

        scrollSpy.update();
        return () => {
            Events.scrollEvent.remove("begin");
            Events.scrollEvent.remove("end");
        };
    });

    return (
        <>

            <NavMenuContainer isDarkMode={isDarkMode} className="nav-menu" isClosed={isClosed}>

                <CloseIconLink isDarkMode={isDarkMode} onClick={onClose} to="">
                    <CloseIcon />
                </CloseIconLink>


                <NavMenuItems className="nav-menu-items scroll-spy">

                    {appBarData.menuItems.map((item) => (
                        <NavItem
                            key={item.itemId}
                            className="nav-item scroll-link"
                            to={item.itemId}
                            href={`#${item.itemId}`}
                            spy={true}
                            activeClass="active"
                            offset={-200}
                            isDarkMode={isDarkMode}
                        >
                            <NavIcon isDarkMode={isDarkMode}>
                                {icons[item.iconKey]}
                            </NavIcon>
                            <NavText className="nav-text">
                                {item.title}
                            </NavText>
                        </NavItem>
                    ))}

                </NavMenuItems>

            </NavMenuContainer>
        </>
    );
};

export default NavMenu;
