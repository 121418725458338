import { Link } from "react-router-dom";
import styled from "styled-components";

import { device } from "../../../../configs/breakpoints";



export const BlogItemCard = styled(Link)`
    width: 100%;
    align-items: stretch;
    border-radius: 1.5rem;
    position: relative;
    overflow: hidden;
    border: 1px solid #161c2722;
    margin: 1rem 0.5rem;
    background-color: white;
    color: var(--day-text-color);
    box-shadow: none;
    transition: all 0.3s ease-in-out;
    bottom: 0px;
    @media ${device.tablet} {
        width: 46%;
    }
    @media ${device.laptop} {
        width: 46%;
    }
    @media ${device.laptopL} {
        width: 46%;
    }
    &:hover {
        cursor: pointer;
        box-shadow: 6px 6px 12px 0px rgb(44 54 76 / 15%);
        bottom: 4px;
    }
`;

export const BlogItemImage = styled.img`
    width: 100%;
    height: 12rem;
    transition: 0.3s;
    object-fit: cover;
`;

export const BlogItemDetailsContainer = styled.div`
    width: auto;
    display: flex;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 0.5rem;
    flex-direction: row;
    transition: 0.3s;
    justify-content: space-between;
`;

export const BlogDetailText = styled.span`
    color: var(--day-text-color);
    opacity: 0.6;
    font-size: 0.8rem;
    transition: 0.3s;
`;

export const BlogItemTitle = styled.h2`
    font-family: var(--heading-font);
    font-size: 1.2rem;
    margin-top: 0.5rem;
    margin-right: 1rem;
    margin-left: 1rem;
    font-family: var(--blog-heading-font);
    overflow: hidden;
    text-overflow: ellipsis;
    transition: 0.3s;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2; 
    -webkit-box-orient: vertical;
`;

export const BlogItemExcerpt = styled.div`
    font-size: 0.9rem;
    overflow: hidden;
    font-family: var(--blog-font);
    margin: 0.5rem 1rem 1rem 1rem;
    text-overflow: ellipsis;
    display: -webkit-box;
    transition: 0.3s;
    -webkit-line-clamp: 4; /* number of lines to show */
    line-clamp: 4; 
    -webkit-box-orient: vertical;
`;