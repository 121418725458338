
import React from 'react'
import useGetRelatedBlogPosts from '../../../../data/apis/get_related_blog_posts'
import RelatedPostItem from './RelatedPostItem'
import { RelatedHeading, RelatedWrapper } from './RelatedPosts.elements'

const RelatedPosts = ({ categoryIds, postId }) => {

    const { isPending, error, data: posts } = useGetRelatedBlogPosts(categoryIds)

    if (isPending) return <div></div>
    if (posts == null) return <div></div> 
    if (error != null) return <div></div>
    console.log(posts)
    return (
        <>
            <RelatedWrapper>
                <RelatedHeading>Read more like this</RelatedHeading>
                {
                    posts.map((post) => postId != post.id && <RelatedPostItem post={post}/> )
                }
            </RelatedWrapper>
        </>
    )
}

export default RelatedPosts