import styled from 'styled-components';
import { ReactComponent as Home } from '../../assets/images/home.svg';
import { ReactComponent as About } from '../../assets/images/about.svg';
import { ReactComponent as Work } from '../../assets/images/work.svg';
import { ReactComponent as Contact } from '../../assets/images/contact.svg';

import { FiX } from 'react-icons/fi';

import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { device } from '../../configs/breakpoints';


export const NavMenuContainer = styled.div`
    position: absolute;
    top: 0px;
    right: ${props => props.isClosed ? "-100%" : "0px"};
    height: 100vh;
    width: 90%;
    background-color: ${props => props.isDarkMode ? "var(--night-card-surface-color)" : "var(--primary-color)"};
    z-index: 1000;
    transition: all 0.5s ease-in-out;
    box-shadow: 0px 0px 12px rgba(0,0,0,0.3);

    @media ${device.tablet} {
        position: static;
        height: fit-content;
        width: 80%;
        background-color: transparent;
        z-index: auto;
        box-shadow: none;
    }
`;

export const NavMenuItems = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 48px;

    @media ${device.tablet} {
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 0px;
    }
`;



export const NavIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    width: 24px;
    height: 24px;
    transition: all 0.5s ease-in-out;

    &:hover svg path {
        fill: var(--primary-color);
    }
    & svg path {
        fill: #FFFFFFBB;
        transition: all 0.5s ease-in-out;
        @media ${device.tablet}{
            fill: ${props => props.isDarkMode ? "#FFFFFFBB" : "var(--day-text-color)"};
        }
    }
    @media ${device.tablet} {
        position: absolute;
        margin-right: 0px;
        opacity: 1;

    }

`;

export const NavText = styled.div`
    font-size: 16px;
    opacity: 1;
    transition: all 0.5s ease-in-out;
    text-transform: uppercase;

    @media ${device.tablet} {
        opacity: 0;
        color: var(--primary-color);
    }
`;

export const CloseIconLink = styled(Link)`
    color: ${props => props.isDarkMode ? "var(--primary-color)" : "white"};
    width: 36px;
    height: 36px;
    margin: 16px;
    border: 3px solid ${props => props.isDarkMode ? "var(--primary-color)" : "white"};
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media ${device.tablet} {
        display: none;
    }
`;

export const CloseIcon = styled(FiX)`
    width: 32px;
    height: 32px;
`;


export const HomeIcon = styled(Home)`

`;

export const AboutIcon = styled(About)`

`;

export const WorkIcon = styled(Work)`

`;

export const ContactIcon = styled(Contact)`

`;


export const NavItem = styled(ScrollLink)`
    color: #FFFFFFBB;
    width: 100%;
    text-align: center;
    padding: 24px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: none;
    transition: all 0.5s ease-in-out;

    @media ${device.tablet} {
        width: 100px;

        &:hover {
            ${NavText} {
                opacity: 1;
            }
            ${NavIcon} {
                opacity: 0;
                & svg path {
                    fill: var(--primary-color);
                }
            }
        }
    }

    @media ${device.laptopS} {
        margin: auto 2%;
    }

    &.active {
        background: ${props => props.isDarkMode ? "var(--night-surface-color-dark)" : "#FFFFFF33"};

        @media ${device.tablet} {
            background: transparent;
        }

        & svg path {
            fill: ${props => props.isDarkMode ? "var(--primary-color)" : "var(--day-text-color)"};
            @media ${device.tablet} {
                fill: var(--primary-color);
            }
        }
        ${NavText} {
            color: ${props => props.isDarkMode ? "var(--primary-color)" : "var(--day-text-color)"};

            @media ${device.tablet} {
                color: var(--primary-color);
            }
        }
    }
`;