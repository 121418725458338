import styled from 'styled-components';
import { Section } from '../../App.elements';

import { device } from '../../configs/breakpoints';

export const PortfolioContainer = styled(Section)`
    margin-top: 120px;
`;

export const WorkItemsContainer = styled.div`
    display: flex;
    margin-top: 64px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
`;

export const WorkItemWrap = styled.div`
    width: 100%;
    @media ${device.laptop} {
        width: 45%;
    }
    @media ${device.laptopL} {
        width: 42%;
    }
`;