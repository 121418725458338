
import React from 'react'
import { Link } from 'react-router-dom'
import { FooterContainer, FooterLinks, FooterText } from './BlogFooter.elements'

const BlogFooter = () => {
  return (
    <>
        <FooterContainer>
            <FooterLinks>
                <Link to={"/privacy"}>Privacy</Link>
                <Link to={"/"} >About</Link>
                <Link to={"/blog"} >Blog</Link>
            </FooterLinks>
            <FooterText>Built by Prabhat Pandey <br/> using ReactJS</FooterText>
        </FooterContainer>
    </>
  )
}

export default BlogFooter