import axios from "axios";
import { useState, useEffect } from "react";

const useGetBlogPosts = () => {
    const abortController = new AbortController()

    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios
            .get(
                "https://prabhatsdp.com/wp-json/wp/v2/posts",
                {
                    params: { categories: "3,4,5,8" },
                    signal: abortController.signal
                }
            )
            .then((response) => {
                console.log("RESPONSE==> ", response)
                const postList = []
                response.data.map((item) => {
                    const post = {}
                    post.title = item.title.rendered
                    post.date = item.modified_gmt
                    post.slug = item.slug
                    post.excerpt = item.excerpt.rendered
                    post.author = item.yoast_head_json.author
                    post.images = item.yoast_head_json.og_image
                    postList.push(post)
                })
                setData(postList)
                setIsPending(false)
            })
            .catch((error) => {
                console.log("ERROR==> ", error)
                setError(error)
            })
        // abort the fetch
        return () => abortController.abort();
    }, [])

    return { data, isPending, error };
}

export default useGetBlogPosts