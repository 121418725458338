import styled from 'styled-components';

import { device } from '../../configs/breakpoints';

export const PolicyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--blog-font);
    & .title, .subtitle, .heading_1 {
        font-family: var(--blog-heading-font);
    }

    & a {
        cursor: pointer;
    }
`

export const PolicyContent = styled.div`
    padding: 6rem 2rem;
    max-width: 1200px;
    @media ${device.tablet} {
        padding: 5rem 4rem;
        max-width: 1200px;
    }
`;