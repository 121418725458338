import React from 'react'
import format from 'date-fns/format'
import PlaceHolderImageUrl from '../../../../assets/images/play_store_banner_1080.webp'
import {
    BlogDetailText,
    BlogItemCard,
    BlogItemDetailsContainer,
    BlogItemExcerpt,
    BlogItemImage,
    BlogItemTitle
} from './BlogItem.elements'

const BlogItem = ({
    title,
    date,
    author,
    content,
    slug,
    images
}) => {
    let imageUrl = ""
    if (images) {
        imageUrl = images[0].url
    }
    return (
        <>
            <BlogItemCard to={{ pathname: "/blog/" + slug, state: { name: "Prabhat" } }}>
                {imageUrl
                    ? <BlogItemImage src={imageUrl}></BlogItemImage>
                    : <BlogItemImage src={PlaceHolderImageUrl}></BlogItemImage>
                }
                <BlogItemDetailsContainer>
                    <BlogDetailText>By {author}</BlogDetailText>
                    <BlogDetailText>{format(new Date(date), "dd MMM, yyyy")}</BlogDetailText>
                </BlogItemDetailsContainer>
                <BlogItemTitle>{title}</BlogItemTitle>
                <BlogItemExcerpt dangerouslySetInnerHTML={{ __html: content }} />
            </BlogItemCard>
        </>
    )
}

export default BlogItem
