import styled from "styled-components";

import { device } from "../../../../configs/breakpoints";

export const BlogPostContainer = styled.section`
    width: 100%;
    display: flex;
    flex-direction: row;
    max-width: 1280px;
    min-height: 100vh;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: stretch;
    padding: 6rem 2rem;
    background-color: #FCFFFC;
    font-family: var(--blog-font);
    color: var(--day-text-color-blog);
`;

export const BlogContentArticle = styled.article`
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    @media ${device.tabletL} {
        width: 66%;
    }
`;

export const BlogContentSidebar = styled.aside`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    overflow: visible;
    @media ${device.tabletL} {
        width: 33%;
        position: sticky;
        top: 3rem;
    }
`;

export const SidebarCard = styled.div`
    width: 100%;
    border: 1px solid #161c2722;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    border-radius: 1rem;
    @media ${device.tabletL} {
        width: 95%;
        /* position: sticky; */
        margin-top: 0;
        /* top: 0; */
    }
`;


export const BlogHeading = styled.h1`
    font-family: var(--blog-heading-font);
    font-weight: 700;
    font-size: 2.2rem;
    transition: all 0.5s ease-in-out;
`;

export const BlogContent = styled.div`
    font-size: 1rem;
    font-weight: 400;
    margin-top: 1rem;
    line-height: 1.2;
    transition: all 0.5s ease-in-out;
    & code {
        width: 80%;
        font-size: small;
    }
    & p {
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
        cursor: text;
    }
    & pre {
        margin-top: 24px;
    }
    & a {
        cursor: pointer;
        font-weight: 400;
        transition: all 0.5s ease-in-out;
        &:hover {
            color: var(--day-text-color-blog);
            border-bottom: 1px solid var(--day-text-color-blog);
        }
    }
    & h1 {
        font-weight: 700;
        margin-top: 2rem;
        font-family: var(--blog-heading-font);
    }
    & h2 {
        font-weight: 700;
        margin-top: 1.6rem;
        font-family: var(--blog-heading-font);
    }
    & h3 {
        font-weight: 700;
        margin-top: 1.4rem;
        font-family: var(--blog-heading-font);
    }
    & h4 {
        font-weight: 700;
        margin-top: 1rem;
        font-family: var(--blog-heading-font);
    }
    & h5 {
        font-weight: 700;
        margin-top: 1rem;
        font-family: var(--blog-heading-font);
    }
    & h6 {
        font-weight: 700;
        margin-top: 1rem;
        font-family: var(--blog-heading-font);
    }
    & img {
        width: 100%;
        height: fit-content;
        object-fit: cover;
    }
    & figure.wp-block-image {
        margin-top: 2rem;
        width: auto;
        margin-bottom: 2rem;
        border-radius: 0.4rem;
        overflow: hidden;
        border: 1px solid rgba(0,0,0,0.2);
    }
    & figcaption {
        margin: 0.5rem 1rem 0.7rem 1rem;
        font-size: 0.7rem;
    }
    & code[class*="language-"], pre[class*="language-"] {
        font-family: var(--code-font);
        font-size: 0.8rem;
    }
    & :not(pre) > code[class*="language-"], pre[class*="language-"] {
        border-radius: 1rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-bottom: 2rem;
        padding-top: 2rem;
    }
`;