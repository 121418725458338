import { useState, useEffect } from "react";

const useGetReadingTime = (content) => {

    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
    
        const length = content.length
        const words = length / 8
        const minutes = parseInt(words/200)

        if(minutes != undefined) {
            let text = ""
            if(minutes < 1) text = "< 1 minutes read"
            else text = minutes + " minutes read" 
            setIsPending(false)
            setData({length, words, minutes, text})
        } else {
            setError("Time couldn't be calculated")
        }
    }, [])
    
    return {data, isPending, error}

}

export default useGetReadingTime