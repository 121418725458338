import styled from 'styled-components';

import { FooterLinks } from '../../screens/blog/components/blogfooter/BlogFooter.elements';

export const FooterContainer = styled.section`
    height: auto;
    width: 100%;
    background: ${props => props.isDarkMode ? "var(--night-surface-color-dark)" : "var(--day-surface-color-dark)"};
    /* color: ${props => props.isDarkMode ? "var(--night-text-color)" : "var(--day-text-color"}; */
    transition: background 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
`;

export const FooterText = styled.p`
    text-align: center;
    font-size: 14px;
    width: 230px;
`;

export const HomeFooterLinks = styled(FooterLinks)`
    & a {
        color: ${props => props.darkmode ? "var(--primary-color)" : "var(--day-text-color)"};
        &:hover {
            color: ${props => props.darkmode ? "var(--night-text-color)" : "white"};
        }
    }
`;