import styled from 'styled-components';
import { device } from './configs/breakpoints';
import { Element as ScrollElement} from 'react-scroll';
// import ScrollAnimation from 'react-animate-on-scroll';
import {AnimationOnScroll} from 'react-animation-on-scroll';
export const AppContainer = styled.div`
    background-color: ${props => props.isDarkMode ? "var(--night-surface-color)" : "var(--day-surface-color)"};
    width: 100%;
    height: 100%;
    min-height: 100vh;
    color: ${props => props.isDarkMode ? "var(--night-text-color-light)" : "var(--day-text-color-light)"};
    transition: all 0.5s ease-in-out;
    padding-bottom: ${ props => props.blog ? "0" : "56px"};
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;

    @media ${device.tablet} {
        font-size: 24px;
        padding-bottom: 0px;
    }

    cursor: ${props => props.isBrowser ? "none" : "auto"}
`;

export const Spacer = styled.div`
    flex-grow: 1;
`;

export const Section = styled(ScrollElement)`
    width: 100%;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    z-index: 1;

    @media ${device.tablet} {
        max-width: 82%;
    }

    @media ${device.desktop} {
        max-width: 1200px;
    }
`;

export const AnimateEnter = styled(AnimationOnScroll).attrs(() => ({
    animateIn: "fadeInUp",
    animateOnce: true,
    offset: 100,
    duration: 1.5
}))`

`;