import styled from 'styled-components';

import { FiFacebook, FiTwitter, FiGithub, FiLinkedin } from 'react-icons/fi';
import { device } from '../../configs/breakpoints';

export const SocialBarContainer = styled.div`
    width: 100%;
    height: 56px;
    background-color: ${props => props.isDarkMode ? "var(--night-card-surface-color)" : "var(--day-card-surface-color)"};
    position: fixed;
    bottom: 0;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, ${props => props.isDarkMode ? "0.25" : "0.15"});
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    transition: all 0.5s ease-in-out;
    z-index: 998;

    @media ${device.tablet} {
        left: 0;
        width: 80px;
        height: 100vh;
        margin: auto;
        background: transparent;
        box-shadow: none;
    }

    @media ${device.laptopS} {
        width: 100px;
    }
`;

export const SocialIcons = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    transition: all 0.5s ease-in-out;

    @media ${device.tablet} {
        bottom: null;
        left: 0;
        width: 80px;
        height: 40vh;
        margin: auto;
        background: transparent;
        flex-direction: column;
        justify-content: space-around;
    }


`;


const iconStyles = `
    width: 24px;
    height: 24px;
    stroke: var(--primary-color);
    color: var(--primary-color);
    transition: color 0.5s ease-in-out, stroke 0.3s ease-in-out, fill 0.3s ease-in-out;
`;


export const FacebookIcon = styled(FiFacebook)`
    ${iconStyles}
    fill: ${props => props.isDarkMode ? "transparent" : "var(--primary-color)"}
`;
export const TwitterIcon = styled(FiTwitter)`
    ${iconStyles}
    fill: ${props => props.isDarkMode ? "transparent" : "var(--primary-color)"}
`;
export const LinkedInIcon = styled(FiLinkedin)`
    ${iconStyles}
    fill: ${props => props.isDarkMode ? "transparent" : "var(--primary-color)"}
`;
export const GithubIcon = styled(FiGithub)`
    ${iconStyles}
    fill: ${props => props.isDarkMode ? "transparent" : "var(--primary-color)"}
`;


export const IconLink = styled.a`
    display: flex;
    width: 36px;
    height: 36px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &:hover {
        ${FacebookIcon},
        ${TwitterIcon},
        ${LinkedInIcon},
        ${GithubIcon} {
            fill: var(--primary-color);
            stroke: var(--primary-color);
        }
    }
`;