
import React from 'react'
import { RelatedItemAuthor, RelatedItemDetailsWrapper, RelatedItemHeading, RelatedItemImage, RelatedItemWrapper } from './RelatedPosts.elements'
import PlaceHolderImageUrl from '../../../../assets/images/play_store_banner_1080.webp'

const RelatedPostItem = ({ post }) => {
    return (
        <>
            <RelatedItemWrapper to={{ pathname: "/blog/" + post.slug, state: { name: "Prabh" } }}>
                {
                    post.images && post.images[0] && post.images[0].url
                        ? <RelatedItemImage src={post.images[0].url} />
                        : <RelatedItemImage src={PlaceHolderImageUrl} />
                }
                <RelatedItemDetailsWrapper>
                    <RelatedItemHeading>{post.title}</RelatedItemHeading>
                    <RelatedItemAuthor>{post.author}</RelatedItemAuthor>
                </RelatedItemDetailsWrapper>
            </RelatedItemWrapper>
        </>
    )
}

export default RelatedPostItem